exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-accountants-tsx": () => import("./../../../src/pages/accountants.tsx" /* webpackChunkName: "component---src-pages-accountants-tsx" */),
  "component---src-pages-affiliates-tsx": () => import("./../../../src/pages/affiliates.tsx" /* webpackChunkName: "component---src-pages-affiliates-tsx" */),
  "component---src-pages-api-technology-partners-tsx": () => import("./../../../src/pages/api-technology-partners.tsx" /* webpackChunkName: "component---src-pages-api-technology-partners-tsx" */),
  "component---src-pages-become-a-partner-tsx": () => import("./../../../src/pages/become-a-partner.tsx" /* webpackChunkName: "component---src-pages-become-a-partner-tsx" */),
  "component---src-pages-business-tsx": () => import("./../../../src/pages/business.tsx" /* webpackChunkName: "component---src-pages-business-tsx" */),
  "component---src-pages-cgt-calculator-tsx": () => import("./../../../src/pages/cgt-calculator.tsx" /* webpackChunkName: "component---src-pages-cgt-calculator-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-dividend-calculator-tsx": () => import("./../../../src/pages/dividend-calculator.tsx" /* webpackChunkName: "component---src-pages-dividend-calculator-tsx" */),
  "component---src-pages-dividend-tracker-tsx": () => import("./../../../src/pages/dividend-tracker.tsx" /* webpackChunkName: "component---src-pages-dividend-tracker-tsx" */),
  "component---src-pages-etf-tracker-tsx": () => import("./../../../src/pages/etf-tracker.tsx" /* webpackChunkName: "component---src-pages-etf-tracker-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-financial-advisers-tsx": () => import("./../../../src/pages/financial-advisers.tsx" /* webpackChunkName: "component---src-pages-financial-advisers-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investment-portfolio-performance-tsx": () => import("./../../../src/pages/investment-portfolio-performance.tsx" /* webpackChunkName: "component---src-pages-investment-portfolio-performance-tsx" */),
  "component---src-pages-investment-portfolio-tax-tsx": () => import("./../../../src/pages/investment-portfolio-tax.tsx" /* webpackChunkName: "component---src-pages-investment-portfolio-tax-tsx" */),
  "component---src-pages-maintenance-tsx": () => import("./../../../src/pages/maintenance.tsx" /* webpackChunkName: "component---src-pages-maintenance-tsx" */),
  "component---src-pages-mutual-fund-tracker-tsx": () => import("./../../../src/pages/mutual-fund-tracker.tsx" /* webpackChunkName: "component---src-pages-mutual-fund-tracker-tsx" */),
  "component---src-pages-no-more-spreadsheet-tsx": () => import("./../../../src/pages/no-more-spreadsheet.tsx" /* webpackChunkName: "component---src-pages-no-more-spreadsheet-tsx" */),
  "component---src-pages-portfolio-tracker-tsx": () => import("./../../../src/pages/portfolio-tracker.tsx" /* webpackChunkName: "component---src-pages-portfolio-tracker-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-collection-notice-tsx": () => import("./../../../src/pages/privacy-collection-notice.tsx" /* webpackChunkName: "component---src-pages-privacy-collection-notice-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-referral-terms-and-conditions-tsx": () => import("./../../../src/pages/referral-terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-referral-terms-and-conditions-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-scholarship-tsx": () => import("./../../../src/pages/scholarship.tsx" /* webpackChunkName: "component---src-pages-scholarship-tsx" */),
  "component---src-pages-share-checker-contest-tsx": () => import("./../../../src/pages/share-checker-contest.tsx" /* webpackChunkName: "component---src-pages-share-checker-contest-tsx" */),
  "component---src-pages-share-registries-tsx": () => import("./../../../src/pages/share-registries.tsx" /* webpackChunkName: "component---src-pages-share-registries-tsx" */),
  "component---src-pages-sharesight-professional-terms-of-use-tsx": () => import("./../../../src/pages/sharesight-professional-terms-of-use.tsx" /* webpackChunkName: "component---src-pages-sharesight-professional-terms-of-use-tsx" */),
  "component---src-pages-sharesight-terms-of-use-tsx": () => import("./../../../src/pages/sharesight-terms-of-use.tsx" /* webpackChunkName: "component---src-pages-sharesight-terms-of-use-tsx" */),
  "component---src-pages-smsf-tsx": () => import("./../../../src/pages/smsf.tsx" /* webpackChunkName: "component---src-pages-smsf-tsx" */),
  "component---src-pages-supported-stock-exchanges-managed-funds-mutual-funds-tsx": () => import("./../../../src/pages/supported-stock-exchanges-managed-funds-mutual-funds.tsx" /* webpackChunkName: "component---src-pages-supported-stock-exchanges-managed-funds-mutual-funds-tsx" */),
  "component---src-pages-survey-thanks-tsx": () => import("./../../../src/pages/survey-thanks.tsx" /* webpackChunkName: "component---src-pages-survey-thanks-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blogCategory.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-campaign-page-tsx": () => import("./../../../src/templates/campaignPage.tsx" /* webpackChunkName: "component---src-templates-campaign-page-tsx" */),
  "component---src-templates-help-category-tsx": () => import("./../../../src/templates/helpCategory.tsx" /* webpackChunkName: "component---src-templates-help-category-tsx" */),
  "component---src-templates-individual-partner-tsx": () => import("./../../../src/templates/individualPartner.tsx" /* webpackChunkName: "component---src-templates-individual-partner-tsx" */),
  "component---src-templates-partner-index-tsx": () => import("./../../../src/templates/partnerIndex.tsx" /* webpackChunkName: "component---src-templates-partner-index-tsx" */)
}

